const locationsReducer = (state = {
  places: [],
  lockersByPlace: { Lockers: [] },
  selectedPlace: {},
  analytics: {
    users: [],
    lockers: [],
    doorOpeningsDistribution: [],
    doorOpeningsPerUser: []
  },
  lockersBySize: [],
  locationConfig: []
}, action) => {
  switch (action.type) {
    case "GET_PLACES_SUCCESS":
      return { ...state, places: action.payload }
    case "GET_ALL_PLACES_SUCCESS":
      return { ...state, allPlaces: action.payload }
    case "GET_LOCKERS_BY_PLACE_SUCCESS":
      return { ...state, lockersByPlace: action.payload }
    case "SET_SELECTED_PLACE":
      return { ...state, selectedPlace: action.payload }
    case "GET_PLACE_DETAILS_SUCCESS":
      return { ...state, selectedPlace: action.payload }
    case "GET_PLACE_ANALYTICS_SUCCESS":
      return { ...state, analytics: action.payload }
    case "UPDATE_LOCKER_STATUS_SUCCESS":
      return {
        ...state, lockersByPlace: {
          /*Logs: [
                  {
                    action_id: action.payload.actionId,
                    loker_id: action.payload.lokerId,
                    frontNumber: action.payload.frontNumber,
                    username: ''
                  },
                  ...state.lockersByPlace.Logs.slice(0, -1)
          ],*/
          Lockers: state.lockersByPlace.Lockers.map(item => item.id === action.payload.id ?
            action.payload : item)
        }
      }
    case "GET_LOCKERS_BY_SIZE_SUCCESS":
      return { ...state, lockersBySize: action.payload }
    case "GET_DELIVERY_LOCKERS_SUCCESS":
      return { ...state, deliveryLockers: action.payload }
    case "GET_LOCATION_CONFIG_SUCCESS":
      return { ...state, locationConfig: action.payload }
    default:
      return state
  }
}

export default locationsReducer
