const buildingReducer = (state = { list: false, selected: null }, action) => {
  switch (action.type) {
    case "GET_BUILDING_LIST_SUCCESS":
      return { ...state, list: action.payload }
    case "GET_BUILDING_DETAILS_SUCCESS":
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default buildingReducer